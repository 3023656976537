import { ProposalState } from "..";

function AddPendingMaterialReducer(state: ProposalState) {
  state.pendingMaterials.push({
    commercialConditions: {
      packagedProductId: undefined,
      packagedProductUnitNb: "0",
      category: undefined,
    },
  });
}

export default AddPendingMaterialReducer;
